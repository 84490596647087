import Link from "next/link";
import clsx from "clsx";

interface Props {
  href?: string;
  children?: React.ReactNode;
  className?: string;
  onClick?: () => void;
  type?: "button" | "reset" | "submit";
  variant?: "outlined" | "contained" | "error";
  color?: "primary" | "secondary";
  rounded?: boolean;
  disabled?: boolean;
}

import "./button.scss";

export const Button = ({
  href,
  children,
  className = "",
  onClick,
  type = "button",
  variant = "contained",
  color = "secondary",
  disabled,
  rounded,
  ...props
}: Props) => {
  let colorLocal;

  if (color === "secondary") {
    colorLocal = "bg-secondButton text-white hover:opacity-80";
  }

  if (color === "primary") {
    colorLocal = "bg-button hover:opacity-80 text-primary";
  }

  if (variant === "error") {
    colorLocal = "bg-error hover:opacity-80 text-primary";
  }

  const variantLocal =
    variant === "outlined" && "bg-transparent border-[0.2rem]";

  const borderRadius = rounded && "rounded-md";

  const isDisabled = disabled && "bg-slate-400 opacity-80 hover:opacity-80 cursor-not-allowed";

  let classes = clsx(
    `inline-block py-[1rem] px-[4rem] font-semibold`,
    colorLocal,
    variantLocal,
    borderRadius,
    isDisabled,
    className
  );

  return href ? (
    <Link href={href} className={classes} {...props}>
      {children}
    </Link>
  ) : (
    <button type={type} className={classes} onClick={onClick} {...props}>
      {children}
    </button>
  );
};
