import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import Image from "next/image";

// components
import { ModalContent } from "./ModalContent";
import { SearchSelect } from "@/components/SearchSelect";
import { Button } from "@/components/Button";
import { Input } from "@/components/Input";

// stores
import { useDispatch } from "react-redux";
import { useTypedSelector } from "@/stores/store";
import {
  handleApplyMarkerForLocation,
  handleChangeLabelPosition,
} from "@/stores/layout";

// helpers
import { toast } from "react-toastify";

// markers
import { mapMarkers } from "@/layouts/wallartSettings/mapMarkers";
import clsx from "clsx";

// images
import Top from "@/public/labelPositions/icon-top.png";
import Left from "@/public/labelPositions/icon-left.png";
import Right from "@/public/labelPositions/icon-right.png";
import Bottom from "@/public/labelPositions/icon-down.png";

export const Markers = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { product_id, id, from } = router.query;
  const { locations, renderLabels } = useTypedSelector(
    ({ layout }) => layout?.layout
  );
  const currentMarker = useTypedSelector(({ modals }) => modals.markersPanel);

  const [markers, setMarkers] = useState(mapMarkers());

  const currentLocation = locations.find(
    loc => loc.place_id == currentMarker.locationId
  );

  const handleChangeLabelPos = pos => {
    dispatch(
      handleChangeLabelPosition({
        labelPosition: pos,
        place_id: currentMarker.locationId,
      })
    );
  };

  return (
    <div className="w-full">
      {renderLabels && (
        <>
          <h3 className="text-h5 mb-[2rem] text-black">Label Position</h3>
          <div className="mb-[2rem]">
            <button
              className={clsx(
                "mx-2 w-[7rem] h-[7rem] relative border rounded-2xl overflow-hidden",
                currentLocation?.labelPosition == "top" && "border-black"
              )}
              onClick={() => handleChangeLabelPos("top")}
            >
              <Image src={Top.src} alt="TOP" layout="fill" />
            </button>
            <button
              className={clsx(
                "mx-2 w-[7rem] h-[7rem] relative border rounded-2xl overflow-hidden",
                currentLocation?.labelPosition == "left" && "border-black"
              )}
              onClick={() => handleChangeLabelPos("left")}
            >
              <Image src={Left.src} alt="TOP" layout="fill" />
            </button>
            <button
              className={clsx(
                "mx-2 w-[7rem] h-[7rem] relative border rounded-2xl overflow-hidden",
                currentLocation?.labelPosition == "right" && "border-black"
              )}
              onClick={() => handleChangeLabelPos("right")}
            >
              <Image src={Right.src} alt="TOP" layout="fill" />
            </button>
            <button
              className={clsx(
                "mx-2 w-[7rem] h-[7rem] relative border rounded-2xl overflow-hidden",
                currentLocation?.labelPosition == "bottom" && "border-black"
              )}
              onClick={() => handleChangeLabelPos("bottom")}
            >
              <Image src={Bottom.src} alt="TOP" layout="fill" />
            </button>
          </div>
        </>
      )}

      <h3 className="text-h5 mb-[2rem] text-black">Markers</h3>
      <div className="flex flex-wrap gap-[1rem]">
        {markers.map((marker, idx) => {
          return (
            <div
              key={idx}
              className={clsx(
                "w-[calc(20%-0.8rem)] flex justify-center items-center border rounded-2xl overflow-hidden  cursor-pointer p-[2rem] bg-white",
                currentLocation?.markerId == idx && "border-black"
              )}
              onClick={() =>
                dispatch(
                  handleApplyMarkerForLocation({
                    markerId: marker.id,
                    place_id: currentMarker.locationId,
                  })
                )
              }
            >
              {marker.iconExample}
            </div>
          );
        })}
      </div>
    </div>
  );
};
