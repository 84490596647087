import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

// constants
import { productsVariations } from "@/constants/constants";

import { isEqual, isEmpty, omit } from "lodash";
import { useDispatch } from "react-redux";
// helpers
import { toast } from "react-toastify";
import {
  updateUserCartStorage,
  storagePoster,
  updateCartItem,
  saveCartStorage,
  getCartStorage,
} from "@/helpers/storageData";

// apis
import { api } from "@/axios";
import { useTranslation } from "react-i18next";

const initialState = {
  cart: [],
};

interface DeleteItemPayload {
  layout: any;
  promiseMessage?: string;
  successMessage?: string;
}

const cart = createSlice({
  name: "cart",
  initialState,
  reducers: {
    handleGetCart(state: any) {
      const cartStorage = localStorage.getItem("cart-storage");

      if (cartStorage) {
        const cartItems = JSON.parse(cartStorage);

        if (cartItems?.length) {
          state.cart = cartItems;
        }
      } else {
        localStorage.setItem("cart-storage", JSON.stringify([]));
      }
    },

    handleDeleteItem(state: any, action: PayloadAction<DeleteItemPayload>) {
      const { layout, promiseMessage, successMessage } = action.payload;
      const cartStorage = localStorage.getItem("cart-storage");

      //   return;

      if (cartStorage) {
        const cartItems = JSON.parse(cartStorage);
        const newCart = cartItems.filter(
          (item: any) => item.path !== layout.path
        );

        state.cart = newCart;
        localStorage.setItem("cart-storage", JSON.stringify(newCart));

        const rq = api
          .delete(`cart?uuid=${layout.uuid}`)
          .then(async ({ data, message }: any) => {
            toast.success(
              successMessage ?? "Project and preview image deleted."
            );
          })
          .catch(({ error }) => {});

        toast.promise(rq, {
          pending: promiseMessage,
        });
      }
    },
  },
});

export const { handleGetCart, handleDeleteItem } = cart.actions;

export default cart.reducer;
