import { useEffect } from "react";
import { Spin } from "antd";
import clsx from "clsx";
import { useRouter } from "next/router";
import { useTypedSelector } from "@/stores/store";
import { useDispatch } from "react-redux";
import { handleCloseModals } from "@/stores/modals";

export const GlobalLoader = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const globalLoaderState = useTypedSelector(
    ({ modals }) => modals.globalLoader
  );

  useEffect(() => {
    dispatch(handleCloseModals());
  }, [router.pathname]);

  return (
    <div
      className={clsx(
        "w-full h-full fixed top-0 left-0 right-0 bottom-0 bg-secondaryBg z-[999] items-center justify-center ",
        globalLoaderState ? "flex" : "hidden"
      )}
    >
      <Spin
        size="large"
        wrapperClassName="global-loader"
        className="global-loader"
      />
    </div>
  );
};
