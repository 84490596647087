// routes
import { popularWallartsRoot, productRoutes } from "@/constants/routers";

//
import terra from "@/public/mapColors/terra.png";
import metropolis from "@/public/mapColors/metropolis.png";
import horizon from "@/public/mapColors/horizon.png";
import example from "@/public/example.png";
import exampleTest from "@/public/example-test.png";
import LineArt from "@/public/lineart-example.png";

// import streetmap from "@/public/wallart-preview/streetmap-preview-second.jpg";
// import lineart from "@/public/wallart-preview/lineart-preview-second.jpg";
// import starmap from "@/public/wallart-preview/starmap-preview-second.jpg";
// import zodiac from "@/public/wallart-preview/zodiac-preview-second.jpg";

import streetmap_1 from "@/public/wallart-preview/streetmap_1.jpg";

import starmap_0 from "@/public/wallart-preview/starmap_0.jpg";

import lineart_0 from "@/public/wallart-preview/lineart_0.jpg";

import zodiac_0 from "@/public/wallart-preview/zodiac_1.jpg";
import zodiac_2 from "@/public/wallart-preview/zodiac_2.jpg";

export const design_category = [
  { label: "Family", value: "family" },
  { label: "Travel", value: "travel" },
  { label: "Astrology", value: "astrology" },
  { label: "Life Events", value: "life_events" },
  { label: "Sports", value: "sports" },
  { label: "Couples", value: "couples" },
  { label: "Places", value: "places" },
  { label: "History", value: "history" },
  { label: "Landmarks", value: "landmarks" },
  { label: "Nature", value: "nature" },
  { label: "Design Ideas", value: "design_ideas" },
];

export const cities = [
  { label: "Ukraine Posters", value: "ukraine_posters" },
  { label: "Kiev Posters", value: "kiev_posters" },
  { label: "Lviv Posters", value: "lviv_posters" },
  { label: "Amsterdam Posters", value: "amsterdam_posters" },
  { label: "Barcelona Posters", value: "barcelona_posters" },
  { label: "Boston Posters", value: "boston_posters" },
  { label: "Chicago Posters", value: "chicago_posters" },
  { label: "Dubai Posters", value: "dubai_posters" },
  { label: "Las Vegas Posters", value: "las_vegas_posters" },
  { label: "London Posters", value: "london_posters" },
  { label: "New York Posters", value: "new_york_city_posters" },
  { label: "Rome Posters", value: "rome_posters" },
  { label: "Tokyo Posters", value: "tokyo_posters" },
  { label: "Washington DC Posters", value: "washington_dc_posters" },
  { label: "Miami Posters", value: "miami_posters" },
  { label: "Paris Posters", value: "paris_posters" },
];

export const design_type = [
  { label: "Anniversary", value: "anniversary" },
  { label: "Baseball", value: "baseball" },
  { label: "Basketball", value: "basketball" },
  { label: "Birthday", value: "birthday" },
  { label: "Cycling", value: "cycling" },
  { label: "Falling In Love", value: "falling_in_love" },
  { label: "Football", value: "football" },
  { label: "Formula 1", value: "furmula1" },
  { label: "Graduation", value: "graduation" },
  { label: "NASCAR", value: "nascar" },
  { label: "National Park", value: "national_park" },
  { label: "Natural Wonders", value: "natural_wonders" },
  { label: "Newborn", value: "newborn" },
  { label: "Running", value: "running" },
  { label: "Soccer", value: "soccer" },
  { label: "Triathlon", value: "triathlon" },
  { label: "Wedding", value: "wedding" },
  { label: "Zodiac", value: "zodiac" },
  { label: "City", value: "city" },
  { label: "Island", value: "island" },
  { label: "River", value: "river" },
  { label: "Neighborhood", value: "neighborhood" },
  { label: "Archaeological Sities", value: "archaeological_sities" },
  { label: "IndyCar", value: "indy_car" },
  { label: "Motorcycling", value: "motorcycling" },
  { label: "Hiking", value: "hiking" },
  { label: "Mountain", value: "mountain" },
];

export const product_type = [
  { label: "Star Maps", value: "star_maps" },
  { label: "Street Maps", value: "street_maps" },
  { label: "Custom Maps", value: "custom_maps" },
  { label: "Line Art", value: "line_art" },
  { label: "Cat Art", value: "cat_art" },
  { label: "Flowers Art", value: "flowers_art" },
];

export const orientation = [
  { label: "Landscape", value: "landscape" },
  { label: "Portrait", value: "portrait" },
];

export const featured = [
  { label: "On Sale", value: "on_sale" },
  { label: "New Arrivals", value: "new_arrivals" },
  { label: "Staff Picks", value: "staff_picks" },
  { label: "Bundles", value: "bundles" },
];

export const PRODUCTS = [
  {
    name: "Street Map",
    description:
      "Map your special moments with custom-made art made to last a lifetime.",
    href: productRoutes.STREETMAP,
    image: streetmap_1,
    translate_key: "streetmap_desc",
  },
  {
    name: "Line Art",
    description:
      "Explore a range of minimalist illustrations, each customizable in text, color, and size, to add elegance and personal flair to any space.",
    href: productRoutes.LINEART,
    image: lineart_0,
    translate_key: "lineart_desc",
  },
  {
    name: "Star Map",
    description:
      "Craft a custom star map that captures the unique arrangement of stars on nights you’ll always remember.",
    href: productRoutes.STARMAP,
    image: starmap_0,
    translate_key: "starmap_desc",
  },
  {
    name: "Zodiac",
    description:
      "Commemorate your moonlit memories with personalized art designed to last, forever honoring your special milestones.",
    href: productRoutes.ZODIAC,
    image: zodiac_0,
    translate_key: "zodiac_desc",
  },
  // {
  //   name: "Coordinates",
  //   description: "Build strategic funnels that will convert",
  //   href: "#",
  //   image: example,
  // },
];

export const FEATUREDS = [
  {
    title: "On Sale Posters",
    link: `${popularWallartsRoot}?featured=on_sale`,
    translate_key: "on_sale",
  },
  {
    title: "New Arrivals",
    link: `${popularWallartsRoot}?featured=new_arrivals`,
    translate_key: "new_arrivals",
  },
  {
    title: "Staff Picks",
    link: `${popularWallartsRoot}?featured=staff_picks`,
    translate_key: "staff_picks",
  },
  {
    title: "Bundles",
    link: `${popularWallartsRoot}?featured=bundles`,
    translate_key: "bundles",
  },
];

export const PRODUCT_TYPES = [
  {
    title: "Star Maps",
    link: `${popularWallartsRoot}?product_type=star_maps`,
    translate_key: "product_type_starmap",
  },
  {
    title: "Street Maps",
    link: `${popularWallartsRoot}?product_type=street_maps`,
    translate_key: "product_type_streetmap",
  },
  {
    title: "Custom Maps",
    link: `${popularWallartsRoot}?product_type=custom_maps`,
    translate_key: "product_type_custommap",
  },
  {
    title: "Line Art",
    link: `${popularWallartsRoot}?product_type=line_art`,
    translate_key: "product_type_lineart",
  },
  {
    title: "Cat Art",
    link: `${popularWallartsRoot}?product_type=cat_art`,
    translate_key: "product_type_catart",
  },
  {
    title: "Flowers Art",
    link: `${popularWallartsRoot}?product_type=flowers_art`,
    translate_key: "product_type_flowers",
  },
];

export const CATEGORIES = [
  {
    title: "Family",
    link: `${popularWallartsRoot}?design_category=family`,
    translate_key: "cat_family",
  },
  {
    title: "Travel",
    link: `${popularWallartsRoot}?design_category=travel`,
    translate_key: "cat_travel",
  },
  {
    title: "Astrology",
    link: `${popularWallartsRoot}?design_category=astrology`,
    translate_key: "cat_astrology",
  },
  {
    title: "Life Events",
    link: `${popularWallartsRoot}?design_category=life_events`,
    translate_key: "cat_life_events",
  },
  {
    title: "Sports",
    link: `${popularWallartsRoot}?design_category=sports`,
    translate_key: "cat_sports",
  },
  {
    title: "Couples",
    link: `${popularWallartsRoot}?design_category=couples`,
    translate_key: "cat_couples",
  },
  {
    title: "Places",
    link: `${popularWallartsRoot}?design_category=places`,
    translate_key: "cat_places",
  },
  {
    title: "History",
    link: `${popularWallartsRoot}?design_category=history`,
    translate_key: "cat_history",
  },
  {
    title: "Landmarks",
    link: `${popularWallartsRoot}?design_category=landmarks`,
    translate_key: "cat_landmarks",
  },
  {
    title: "Nature",
    link: `${popularWallartsRoot}?design_category=nature`,
    translate_key: "cat_nature",
  },
  {
    title: "Design Ideas",
    link: `${popularWallartsRoot}?design_category=design_ideas`,
    translate_key: "cat_design_ideas",
  },
];

export const TOP_SELL_SPORTS = [
  {
    title: "Baseball Posters",
    link: `${popularWallartsRoot}?design_type=baseball`,
    translate_key: "design_baseball",
  },
  {
    title: "Basketball Posters",
    link: `${popularWallartsRoot}?design_type=basketball`,
    translate_key: "design_basketball",
  },
  {
    title: "Cycling Posters",
    link: `${popularWallartsRoot}?design_type=cycling`,
    translate_key: "design_cycling",
  },
  {
    title: "Football Posters",
    link: `${popularWallartsRoot}?design_type=football`,
    translate_key: "design_football",
  },
  {
    title: "Soccer Posters",
    link: `${popularWallartsRoot}?design_type=soccer`,
    translate_key: "design_soccer",
  },
  {
    title: "Triathlon Posters",
    link: `${popularWallartsRoot}?design_type=triathlon`,
    translate_key: "design_triathlon",
  },
];

export const gift = [
  { label: "Gifts for Athletes", value: "athletes" },
  { label: "Gifts for Couples", value: "couples" },
  { label: "Gifts for Dad", value: "dad" },
  { label: "Gifts for Family", value: "family" },
  { label: "Gifts for Frequent Travelers", value: "frequent_travelers" },
  { label: "Gifts for Friends", value: "friends" },
  { label: "Gifts for Grads", value: "grads" },
  { label: "Gifts for Grandparents", value: "grandparents" },
  { label: "Gifts for Her", value: "her" },
  { label: "Gifts for Him", value: "him" },
  { label: "Gifts for Husband", value: "husband" },
  { label: "Gifts for Kids", value: "kids" },
  { label: "Gifts for Mom", value: "mom" },
  {
    label: "Gifts for Nature & Outdoor Lovers",
    value: "nature_outdoor_lovers",
  },
  { label: "Gifts for Newborns", value: "newborns" },
  { label: "Gifts for Newlyweds", value: "newlyweds" },
  { label: "Gifts for Sports Fans", value: "sports_fans" },
  { label: "Gifts for Students", value: "students" },
  { label: "Gifts for Wife", value: "wife" },
  { label: "Gifts for Anniversaries", value: "anniversaries" },
  { label: "Gifts for Birthdays", value: "birthdays" },
];

export const GIFTS = [
  {
    title: "Gifts for Friends",
    link: `${popularWallartsRoot}?gift=friends`,
    translate_key: "gift_friends",
  },
  {
    title: "Gifts for Students",
    link: `${popularWallartsRoot}?gift=students`,
    translate_key: "gift_students",
  },
  {
    title: "Gifts for Couples",
    link: `${popularWallartsRoot}?gift=couples`,
    translate_key: "gift_couples",
  },
  {
    title: "Gifts for Dad",
    link: `${popularWallartsRoot}?gift=dad`,
    translate_key: "gift_dad",
  },
  {
    title: "Gifts for Mom",
    link: `${popularWallartsRoot}?gift=mom`,
    translate_key: "gift_mom",
  },
  {
    title: "Gifts for Family",
    link: `${popularWallartsRoot}?gift=family`,
    translate_key: "gift_family",
  },
  {
    title: "Gifts for Grandparents",
    link: `${popularWallartsRoot}?gift=grandparents`,
    translate_key: "gift_grandparents",
  },
  {
    title: "Gifts for Athletes",
    link: `${popularWallartsRoot}?gift=athletes`,
    translate_key: "gift_athletes",
  },
];

export const ORIENTATIONS = [
  {
    title: "Landscape Posters",
    link: `${popularWallartsRoot}?orientation=landscape`,
    translate_key: "landscape",
  },
  {
    title: "Portrait Posters",
    link: `${popularWallartsRoot}?orientation=portrait`,
    translate_key: "portrait",
  },
];

export const CITIES = [
  {
    title: "Ukraine Posters",
    link: `${popularWallartsRoot}?cities=ukraine_posters`,
    translate_key: "ukraine_posters",
  },
  {
    title: "Kiev Posters",
    link: `${popularWallartsRoot}?cities=kiev_posters`,
    translate_key: "kiev_posters",
  },
  {
    title: "Lviv Posters",
    link: `${popularWallartsRoot}?cities=lviv_posters`,
    translate_key: "lviv_posters",
  },
  {
    title: "Amsterdam Posters",
    link: `${popularWallartsRoot}?cities=amsterdam_posters`,
    translate_key: "amsterdam_posters",
  },
  {
    title: "Barcelona Posters",
    link: `${popularWallartsRoot}?cities=barcelona_posters`,
    translate_key: "barcelona_posters",
  },
  {
    title: "Boston Posters",
    link: `${popularWallartsRoot}?cities=boston_posters`,
    translate_key: "boston_posters",
  },
  {
    title: "Chicago Posters",
    link: `${popularWallartsRoot}?cities=chicago_posters`,
    translate_key: "chicago_posters",
  },
  {
    title: "Dubai Posters",
    link: `${popularWallartsRoot}?cities=dubai_posters`,
    translate_key: "dubai_posters",
  },
  {
    title: "Las Vegas Posters",
    link: `${popularWallartsRoot}?cities=las_vegas_posters`,
    translate_key: "las_vegas_posters",
  },
  {
    title: "London Posters",
    link: `${popularWallartsRoot}?cities=london_posters`,
    translate_key: "london_posters",
  },
  {
    title: "New York City Posters",
    link: `${popularWallartsRoot}?cities=new_york_city_posters`,
    translate_key: "new_york_city_posters",
  },
  {
    title: "Rome Posters",
    link: `${popularWallartsRoot}?cities=rome_posters`,
    translate_key: "rome_posters",
  },
  {
    title: "Tokyo Posters",
    link: `${popularWallartsRoot}?cities=tokyo_posters`,
    translate_key: "tokyo_posters",
  },
  {
    title: "Washington DC Posters",
    link: `${popularWallartsRoot}?cities=washington_dc_posters`,
    translate_key: "washington_dc_posters",
  },
  {
    title: "Miami Posters",
    link: `${popularWallartsRoot}?cities=miami_posters`,
    translate_key: "miami_posters",
  },
  {
    title: "Paris Posters",
    link: `${popularWallartsRoot}?cities=paris_posters`,
    translate_key: "paris_posters",
  },
];

export const ALL_OUT_PRODUCTS = [
  {
    title: "Star Map",
    link: productRoutes.STARMAP,
    translate_key: "starmap",
  },
  {
    title: "Street Map",
    link: productRoutes.STREETMAP,
    translate_key: "streetmap",
  },
  {
    title: "Line Art",
    link: productRoutes.LINEART,
    translate_key: "lineart",
  },
  {
    title: "Zodiac Art",
    link: productRoutes.ZODIAC,
    translate_key: "zodiac",
  },
];

export const DESIGNS = [
  {
    title: "Featured",
    links: FEATUREDS,
    translate_key: "design_featured",
  },
  {
    title: "Product Type",
    links: PRODUCT_TYPES,
    translate_key: "design_type",
  },
  {
    title: "Orientation",
    links: ORIENTATIONS,
    translate_key: "design_orientation",
  },
  {
    title: "Categories",
    links: CATEGORIES,
    translate_key: "design_categories",
  },
  {
    title: "Cities",
    links: CITIES,
    translate_key: "design_cities",
  },
];

export const ALL_POSTER_TYPES = [
  {
    title: "All Our Products",
    links: ALL_OUT_PRODUCTS,
    translate_key: "all_our_products",
  },
];
