import Link from "next/link";

// components
import { ModalContent } from "./ModalContent";

import { Button } from "../Button";

// stores
import { useDispatch } from "react-redux";
import { useTypedSelector, AppDispatch } from "@/stores/store";

import { handleCloseModals } from "@/stores/modals";

export const ShareLinkHasExpired = () => {
  const isOpen = useTypedSelector(({ modals }) => modals.shareLinkHasExpired);
  const dispatch: AppDispatch = useDispatch();

  return (
    <ModalContent isModalOpen={isOpen} bgClose={true} onClose={false}>
      <div className="py-[2rem] px-[6rem] flex flex-col items-center justify-center">
        <h3 className="mb-5 text-center font-bold text-body">
          Link has expired.
        </h3>

        <Link
          href="/"
          onClick={() => dispatch(handleCloseModals())}
          className="mt-[2rem]"
        >
          <Button className="text-body">Go to Home</Button>
        </Link>
      </div>
    </ModalContent>
  );
};
