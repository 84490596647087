import { GoogleAnalyticsScript } from "@/analytics";

import { useRouter } from "next/router";
import { useEffect } from "react";
import type { AppProps } from "next/app";
import type { Metadata } from "next";
import Head from "next/head";
import { appWithTranslation, useTranslation } from "next-i18next";
import { Logo } from "@/components/Logo";
// apis
import { api } from "@/axios";

// stores
import { Provider } from "react-redux";
import store from "@/stores/store";
import { useDispatch } from "react-redux";
import { handleSaveUser, handleLogout } from "@/stores/user";
import { handleShowLoginModal } from "@/stores/modals";
import { useTypedSelector } from "@/stores/store";

// helpers
import { ToastContainer, toast } from "react-toastify";

// styles
import "react-toastify/dist/ReactToastify.css";
import "@/styles/globals.scss";

// modals
import { ProductVariations } from "@/components/Modals/ProductVariations";
import { Register } from "@/components/Modals/Register";
import { Login } from "@/components/Modals/Login";
import { ForgotPassword } from "@/components/Modals/ForgotPassword";
import { ResetPassword } from "@/components/Modals/ResetPassword";
import { ProjectAdminSettings } from "@/components/Modals/ProjectAdminSettings";
import { Markers } from "@/components/Modals/Markers";
import { SidePanelLayout } from "@/components/SidePanel/layout";
import { Cart } from "@/components/Modals/Cart";
import { GlobalLoader } from "@/components/GlobalLoader";
import { ShareLinkHasExpired } from "@/components/Modals/ShareLinkExpired";

// TODO: FOR v@
// import { SelectLocationOnMap } from "@/components/Modals/SelectLocationOnMap";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

function CustomApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const dispatch = useDispatch();
  const isOpenProjectSettings = useTypedSelector(
    ({ modals }) => modals.isOpenProjectAdminSettings
  );
  const markersPanel = useTypedSelector(({ modals }) => modals.markersPanel);

  const cartPanel = useTypedSelector(({ modals }) => modals.isOpenCartPanel);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (router.query.admin == "login" && !token) {
      dispatch(handleShowLoginModal());
    }
  }, [router.query.admin]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const resetPasswordToken = localStorage.getItem("token");

    if (token) {
      try {
        api
          .get("/me")
          .then(user => {
            dispatch(handleSaveUser(user));
          })
          .catch(({ response }) => {
            // dispatch(handleLogout());
            // toast.error(response?.data?.error);
          });
      } catch (error) {
        throw new Error(`GET ME ERROR: ${error}`);
      }
    }
  }, []);

  useEffect(() => {
    new PerformanceObserver(entryList => {
      for (const entry of entryList.getEntriesByName(
        "first-contentful-paint"
      )) {
        console.log("FCP candidate:", entry.startTime, entry);
      }
    }).observe({ type: "paint", buffered: true });
  }, []);

  return (
    <>
      <Head>
        <link
          rel="shortcut icon"
          href="https://storage.googleapis.com/mapsmi/public/favicon.ico?v=1"
        />
      </Head>
      <div className={`root font-sans`}>
        <Component {...pageProps} />
        <ProductVariations />
        <Register />
        <Login />
        <ForgotPassword />
        <ResetPassword />

        <GlobalLoader />

        <SidePanelLayout
          isOpen={isOpenProjectSettings}
          bgClose={true}
          className="w-full lg:w-[55rem]"
        >
          <ProjectAdminSettings />
        </SidePanelLayout>
        <SidePanelLayout
          isOpen={markersPanel.state}
          bgClose={true}
          className="w-full lg:w-[55rem]"
        >
          <Markers />
        </SidePanelLayout>
        <SidePanelLayout
          isOpen={cartPanel}
          bgClose={true}
          className="w-full lg:w-[55rem]"
        >
          <Cart />
        </SidePanelLayout>

        {/* TODO: FOR v2 */}
        {/* <SidePanelLayout isOpen={true} bgClose={false}>
          <SelectLocationOnMap />
        </SidePanelLayout> */}
        <ToastContainer
          position="bottom-right"
          hideProgressBar={true}
          closeOnClick
          pauseOnFocusLoss={false}
          pauseOnHover={true}
          theme="dark"
        />

        <ShareLinkHasExpired />
      </div>
    </>
  );
}

function MyApp({ Component, pageProps, router }: AppProps) {
  return (
    <Provider store={store}>
      <CustomApp Component={Component} pageProps={pageProps} router={router} />
      <GoogleAnalyticsScript />
    </Provider>
  );
}

export default appWithTranslation(MyApp);
